import React from "react";
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import loadable from "@loadable/component";
import Layout from "../components/layout";

import BannerImg from "../images/review_banner_img.png";
import AllAgents from "../components/AllAgents/AllAgents";
import ElfSightReview from "../components/ElfSightReview/ElfSightReview";
import { Container } from "react-bootstrap";

const Banner = loadable(() => import("../components/Banner/Banner"));
const ReviewsSlider = loadable(() => import("../components/ReviewsSlider/ReviewsSlider"));
const PatternBanner = loadable(() => import("../components/PatternBanner/PatternBanner"));
const ElfSight = loadable(() => import("../components/ElfSight/ElfSight"));

const Reviews = ({ data }) => {
    const PageData = data?.strapiPage
    const elfData = data?.site.siteMetadata?.elfSight
    const allAgentData = data?.site.siteMetadata?.allAgent
    let isAnyReview = ((elfData.review && elfData.review_page) || allAgentData)

    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>

            <div className="layout-padding-top"></div>

            {PageData.banner && <Banner title={PageData.title} {...PageData.banner} id={PageData.strapi_id} />}


            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.video-review" && <ReviewsSlider data={module.add} id={PageData.strapi_id}/>}
                        {(module.strapi_component === "page-modules.global-module" &&
                            module.select_module === "google_reviews_all" &&
                            isAnyReview) &&
                            <div className="widget-wrapper section-m">
                                <Container>
                                    {elfData.review && elfData.review_page &&
                                        <div className="elf-review-page-module">
                                            <ElfSightReview code={elfData.review_page} />
                                        </div>
                                    }
                                    {allAgentData &&
                                        <div className="elf-review-page-module">
                                            <AllAgents />
                                        </div>
                                    }
                                    {/* {estasData.review &&
                                        <EstasReview estas_key={estasData.estas_key} />
                                    }
                                    {trustPilotData.review &&
                                        <TrustPilot
                                            templateId={trustPilotData.templateId}
                                            businessUnitId={trustPilotData.businessUnitId}
                                            href={trustPilotData.href}
                                        />
                                    } */}
                                </Container>
                            </div>
                        }

                        {module.strapi_component === "page-modules.text-module" && <PatternBanner
                            tag={module.layout === "background_secondary_color" ? "blue" : module.layout === "background_primary_color" ? "brown" : ''}
                            {...module}
                        />
                        }
                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_reviews_all" && elfData.review &&
                            <ElfSight code={elfData.review_page} className="reviews-page" />
                        }
                    </>
                )
            })}
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default Reviews



export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_VIDEO_REVIEW {
              ...VideoReviewFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_MODULE {
              ...TextModuleFragment
            }
        }
    }
    site {
        siteMetadata {
            elfSight {
              review
              review_badge
              review_carousel
              review_page
            }
            allAgent
        }
      }
  }
`